import { C as Component } from "./Component.min.ac9d492e.js";
class Device extends Component {
  onInit() {
    this.elScrollContent = document.querySelector("[data-scroll-content]");
    this.width = window.innerWidth;
    this.height = window.innerHeight;
    this.isReduced = window.matchMedia(`(prefers-reduced-motion: reduce)`) === true || window.matchMedia(`(prefers-reduced-motion: reduce)`).matches === true;
    this.scroll = {
      left: 0,
      top: 0,
      last: 0,
      direction: "up"
    };
    this.isTouch = "ontouchstart" in window || navigator.msMaxTouchPoints > 0;
    this.isIpad = navigator.userAgent.match(/.*(iPad).*/) ? true : false;
    this.isIphone = navigator.userAgent.match(/.*(iPhone).*/) ? true : false;
    this.isAndroid = navigator.userAgent.match(/.*(Android).*/) ? true : false;
    this.isFirefox = navigator.userAgent.match(/.*((f|F)irefox).*/) ? true : false;
    this.isChrome = navigator.userAgent.match(/.*(Chrome).*/) ? true : false;
    this.isSafari = navigator.userAgent.match(/.*(Safari).*/) && !this.isChrome ? true : false;
    this.isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
      navigator.userAgent.toLowerCase()
    );
  }
  onReady() {
    this.onResize();
    this.onScroll();
  }
  onResize() {
    this.width = window.innerWidth;
    this.height = window.innerHeight;
    document.documentElement.style.setProperty("--vh", `${this.height * 0.01}px`);
  }
  onScroll() {
    this.scroll.left = this.elScrollContent.scrollLeft;
    this.scroll.top = this.elScrollContent.scrollTop;
    if (this.scroll.top > this.scroll.last) {
      this.scroll.direction = "down";
    } else {
      this.scroll.direction = "up";
    }
    setTimeout(() => {
      this.scroll.last = this.scroll.top;
    }, 0);
  }
}
const Device$1 = new Device();
export {
  Device$1 as D
};
